(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/ministat/assets/javascripts/ministat.js') >= 0) return;  svs.modules.push('/components/tipsen/ministat/assets/javascripts/ministat.js');
"use strict";

const {
  useEffect,
  useState
} = React;
const {
  useSelector
} = ReactRedux;
const {
  analytics
} = svs.components;
const {
  ReactIcon,
  ReactIconButton
} = svs.ui;
const {
  selectDisplayName
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectSportEvent
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  useCurrentDraw
} = svs.components.tipsen.drawProvider.hooks;
const {
  useComponentFromRegistry
} = svs.components.tipsen.componentRegistry;
const Ministat = _ref => {
  var _sportEvent$externalI;
  let {
    eventNumber,
    matchId
  } = _ref;
  const displayName = useSelector(selectDisplayName);
  const sportEvent = useSelector(state => selectSportEvent(state, matchId));
  const [showMinistat, setShowMinistat] = useState(false);
  const draw = useCurrentDraw();
  const drawId = "".concat(draw.productId, "_").concat(draw.drawNumber);
  const WidgetComponent = useComponentFromRegistry('ministat-widget');
  const toggleMiniStat = event => {
    event.preventDefault();
    if (!showMinistat) {
      analytics.trackEvent({
        category: 'Nya Tipsen',
        action: 'Ministatistik visad',
        opt_label: displayName,
        opt_value: matchId
      });
    }
    setShowMinistat(current => !current);
  };
  useEffect(() => {
    setShowMinistat(false);
  }, [drawId]);
  return React.createElement("div", {
    className: "tipsen-mini-stat"
  }, React.createElement(ReactIconButton, {
    className: "mini-stat-btn",
    disabled: !(sportEvent !== null && sportEvent !== void 0 && (_sportEvent$externalI = sportEvent.externalId) !== null && _sportEvent$externalI !== void 0 && _sportEvent$externalI.sportradarId),
    onClick: toggleMiniStat
  }, React.createElement(ReactIcon, {
    icon: "menu-".concat(showMinistat ? 'up' : 'down'),
    size: "100"
  })), showMinistat && React.createElement(WidgetComponent, {
    matchId: sportEvent.externalId.sportradarId,
    uniqueId: eventNumber
  }));
};
setGlobal('svs.components.tipsen.ministatComponent.Ministat', Ministat);

 })(window);